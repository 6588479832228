#depoimentos {
  display: grid;
  grid-template-rows: 1fr auto;
  background: var(--color-black);
}

#depoimentos > .wrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 40px;
  padding: 40px;
}

#depoimentos h1 {
  color: var(--color-white);
}

#depoimentos > .wrapper .content {
  justify-self: center;
  align-self: center;
}

#depoimentos .main {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}

#depoimentos .card {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  padding: 20px 0;
}

#depoimentos .card > .image > img {
  width: 64px;
  border-radius: 100%;
}

#depoimentos .bubbly {
  margin-left: 50px;
  padding: 20px;
  position: relative;
  background: #fff;
  display: grid;
  grid-template-rows: 1fr auto;
  color: var(--color-dark);
}

#depoimentos .bubbly > p {
  font-size: 14px;
}

#depoimentos .bubbly > strong {
  font-size: 14px;
  padding-top: 10px;
  text-align: right;
}

#depoimentos .bubbly:after {
  content: "";
  position: absolute;
  left: 0;
  top: 25px;
  width: 0;
  height: 0;
  border: 50px solid transparent;
  border-right-color: #fff;
  border-left: 0;
  border-top: 0;
  margin-top: -25px;
  margin-left: -50px;
}

#depoimentos .bottom {
  width: 100%;
  overflow: hidden;
}

#depoimentos .bottom > div {
  width: 0;
  height: 0;
  border-right: 100vw solid transparent;
  border-bottom: 100px solid var(--color-white);
}

@media (min-width: 780px) {
  #depoimentos > .wrapper {
    padding: 40px 80px;
  }

  #depoimentos .card > .image > img {
    width: 100px;
    border-radius: 100%;
  }
}
