:root {
  --color-yellow: #F6D842;
  --color-yellow-dark: #E9C338;
  --color-dark: #4c4c4c;
  --color-grey: #929292;
  --color-black: #202020;
  --color-white: #f4f4f4;
  --color-white-light: #ffffff;
  --shadow: 2px 2px 2px rgba(0, 0, 0, 0.02);
  --shadow-expand: 0px 0px 50px rgba(0, 0, 0, 0.2);
  --max-width: 1100px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
}

ul {
  list-style: none;
}

a, a:hover, a:active {
  text-decoration: none;
}

html, body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

h1 {
  color: var(--color-dark);
  font-size: 36px;
}

button {
  filter: brightness(100%);
  transition: .2s filter;
}

button:hover {
  filter: brightness(92%);
}

.mark-left {
  position: relative;
  left: 18px;
}
.mark-left::before {
  content: "";
  display: block;
  position: absolute;
  margin-left: -16px;
  width: 10px;
  height: 38px;
  background: var(--color-yellow-dark);
}

.mark-right {
  position: relative;
  right: 26px;
}
.mark-right::after {
  content: "";
  display: inline-block;
  position: absolute;
  margin-left: 16px;
  width: 10px;
  height: 38px;
  background: var(--color-yellow-dark);
}

.app {}

section {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.fadeIn {
  animation: fadeIn .2s forwards;
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  99% {
    opacity: 1;
  }  
  100% {
    opacity: 1;
    visibility: inherit;
  }
}

.fadeOut {
  animation: fadeOut .2s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: inherit;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}